'use client';
import qrCode from "../../assets/qr_code_new.svg";
import logo from "../../assets/logo.svg";
// import iosBtn from "../../assets/iosBtn.svg";
// import googleBtn from "../../assets/googleBtn.svg";
// import desktop from "../../assets/desktop.svg";
import mac from "../../assets/macOsIcon.png";
import web from "../../assets/webAppIcon.png";
import windows from "../../assets/windows.svg";
import linux from "../../assets/linux.svg";
import huawei from "../../assets/huawei.svg";
import iPhone from "../../assets/iPhone.svg";
import android from "../../assets/android.svg";
// import vektor from "../../assets/vektor.svg";
import facebook from "../../assets/facebookLogo.svg";
import instagram from "../../assets/instagramLogo.svg";
import linkedin from "../../assets/linkedinLogo.svg";
import x from "../../assets/twitterLogo.svg";
import triangle from "../../assets/trinagleCorner.svg";
import footerTFLogo from "../../assets/footerTFLogo.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import RegulationModal from "../RegulationModal/RegulationModal";
import MePricing from "../../assets/regulativa/regulativaNew/PricingME.pdf";
import EnPricing from "../../assets/regulativa/PricingEN.pdf";
import MBanq from "../../assets/MBanq-Prezentacija-Maj-2024.pdf";
// import { NavLink } from "react-router-dom";

function FooterNew() {
  const { t } = useTranslation("footer");
  const locale = useTranslation("footer").i18n.language;
  const [modal, setModal] = useState(false);

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <div className="bg-gradient-to-r from-[#F4F7FF] to-[#FFFFFF] w-full relative pt-[50px] pb-[75px]">
      {modal && <RegulationModal setModal={setModal} />}
      <img alt="img" src={triangle} className="absolute top-0 right-0" />
      <div className="container mx-auto lg:px-[20px] px-[16px] lg:gap-[64px] gap-[48px] flex flex-col">
        <div className=" pb-[56px] flex lg:flex-row flex-col lg:gap-[107px] gap-6 border-b-[1px] border-solid border-[#C8E64C]">
          <img
            className="2xl:max-w-[116px] 2xl:max-h-[45px] lg:max-w-[87px] lg:max-h-[33.75px] max-w-[116px] max-h-[45px]"
            src={logo}
            alt="logo-img"
          />
          <div className="flex lg:flex-row flex-col  lg:gap-[130px] gap-6 lg:text-[10px] text-[12px] lg:font-bold font-semibold lg:leading-4 leading-[19px] text-[#788497]">
            <p className="lg:max-w-[293px] w-auto">{t("footer.descOne")}</p>
            <p className="lg:max-w-[587px] w-auto xl:pr-0 lg:pr-10 pr-0">
              {t("footer.descTwo")}
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:gap-[72px] gap-[48px]">
          <div className="flex justify-between xl:flex-row flex-col lg:gap-[50px] gap-8">
            <div className="flex md:flex-row flex-col justify-between lg:gap-[56px] gap-[48px]">
              <div className="flex flex-col lg:gap-[32px] gap-[24px]">
                <h4 className="text-[14px] font-bold leading-[19px] text-[#3E6BE4]">
                  {t("footer.headOne")}{" "}
                </h4>
                <img
                  src={footerTFLogo}
                  alt="TF Logo"
                  className="w-[94px] md:hidden flex"
                />
                <div className="md:flex hidden z-20 relative p-3 rounded-2xl mx-auto mb-[42px] 2xl:mb-[42px] lg:mb-0 w-[100px] h-[100px] border-[3px] border-[#c8e64c] items-center justify-center bg-[#163A69]">
                  <img
                    src={qrCode}
                    alt="qr-code"
                    className="relative z-20 w-full h-full"
                  />
                </div>
              </div>
              <div className="flex flex-col lg:gap-[32px] gap-[24px]">
                <h4 className="text-[14px] font-bold leading-[19px] text-[#3E6BE4]">
                  {t("footer.headTwo")}
                </h4>
                <div className="lg:flex flex-wrap md:flex-col flex-row gap-3 hidden">
                  <a
                    href="https://trade.metatrader5.com/terminal"
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer flex items-center gap-[16px]"
                  >
                    <img
                      src={web}
                      alt="webImg"
                      className="2xl:w-[18px] lg:w-[14.5px] w-[16px]  2xl:h-[18px] lg:h-[14.5px]"
                    />
                    <p className="lg:text-[12px] lg:leading-[28px]">Web App</p>
                  </a>
                  <a
                    href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/mt5setup.exe?utm_source=www.metatrader5.com&amp;utm_campaign=download"
                    className="cursor-pointer flex gap-[16px]"
                  >
                    <img
                      src={windows}
                      alt="windowsImg"
                      className="2xl:w-[15px] lg:w-[11.5px] w-[16px] "
                    />
                    <p className="lg:text-[12px] lg:leading-[28px]">
                      Windows App
                    </p>
                  </a>
                  <a
                    href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.pkg.zip?utm_source=www.metatrader5.com&utm_campaign=download.mt5.macos"
                    className="cursor-pointer flex items-center gap-[16px]"
                  >
                    <img
                      src={mac}
                      alt="macImg"
                      className="2xl:w-[18px] lg:w-[14.5px] w-[16px] 2xl:h-[18px] lg:h-[14.5px]"
                    />
                    <p className="lg:text-[12px] lg:leading-[28px]">
                      MacOS App
                    </p>
                  </a>
                  <a
                    href="https://www.mql5.com/en/articles/625?utm_source=www.metatrader5.com&utm_campaign=download.mt5.linux"
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer flex gap-[16px]"
                  >
                    <img
                      src={linux}
                      alt="linuxImg"
                      className="2xl:w-[15px] lg:w-[11.5px] w-[16px] "
                    />
                    <p className="lg:text-[12px] lg:leading-[28px]">
                      Linux App
                    </p>
                  </a>
                </div>
                <div className="sm:w-auto w-[240px] flex flex-wrap md:flex-col flex-row gap-3 lg:hidden">
                  <a
                    href="https://trade.metatrader5.com/terminal"
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer flex items-center gap-[12px]"
                  >
                    <img src={web} alt="webImg" className="w-[15px]" />
                    <p className="text-[12px] leading-[24px] text-[#929395]">
                      Web App
                    </p>
                  </a>
                  <a
                    href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/mt5setup.exe?utm_source=www.metatrader5.com&amp;utm_campaign=download"
                    className="cursor-pointer flex gap-[12px]"
                  >
                    <img src={android} alt="Android" className="w-[15px] " />
                    <p className="text-[12px] leading-[24px] text-[#929395]">
                      Android
                    </p>
                  </a>
                  <a
                    href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.pkg.zip?utm_source=www.metatrader5.com&utm_campaign=download.mt5.macos"
                    className="cursor-pointer flex items-center gap-[12px]"
                  >
                    <img src={iPhone} alt="iPhone/iPad" className="w-[15px]" />
                    <p className="text-[12px] leading-[24px] text-[#929395]">
                      iPhone/iPad
                    </p>
                  </a>
                  <a
                    href="https://www.mql5.com/en/articles/625?utm_source=www.metatrader5.com&utm_campaign=download.mt5.linux"
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer flex gap-[12px]"
                  >
                    <img src={huawei} alt="Huawei" className="w-[15px]" />
                    <p className="text-[12px] leading-[24px] text-[#929395]">
                      Huawei
                    </p>
                  </a>
                </div>
              </div>
              <div className="flex flex-col items-start lg:gap-[32px] gap-[24px] sm:max-w-[190px]">
                <h4 className="text-[14px] lg:font-bold font-semibold leading-[19px] text-[#3E6BE4]">
                  {t("footer.headThree")}
                </h4>
                <div className="flex flex-col items-start gap-6">
                  <p className="text-[12px] leading-[19px]">
                    {t("footer.headThreeDesc")}
                  </p>
                  <NavLink
                    to="/montenegroberze"
                    className="button-small button-primary"
                  >
                    {t("footer.button")}
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col justify-between lg:gap-[39px] gap-[48px]">
              {/* <div className="flex flex-col gap-8">
                <h4 className="text-[14px] font-semibold leading-[19px]">
                  {t("footer.headFour")}
                </h4>
                <div className="flex flex-col text-[12px] font-medium leading-9 max-w-[190px]">
                  <p>{t("footer.headFour-1")}</p>
                  <p>{t("footer.headFour-2")}</p>
                </div>
              </div> */}
              <div className="flex flex-col lg:gap-8 gap-2">
                <h4 className="text-[14px] font-semibold leading-[19px]">
                  {t("footer.headFive")}
                </h4>
                <div className="flex flex-col text-[12px] font-medium leading-9">
                  {/* <NavLink to="/news#us-stock">
                    {t("footer.headFive-1")}
                  </NavLink>
                  <NavLink to="/news#forex">{t("footer.headFive-2")}</NavLink>
                  <NavLink to="/news#other">{t("footer.headFive-3")}</NavLink> */}
                  {/* <p>{t("footer.headFive-4")}</p> */}
                  <p className="cursor-pointer" onClick={handleModal}>{t("footer.headFive-5")}</p>
                  <a target="_blank" href={locale === "en" ? EnPricing : MePricing} className="cursor-pointer">{t("footer.headFive-6")}</a>
                  <a target="_blank" href={MBanq} className="cursor-pointer">Prezentacije</a>
                </div>
              </div>
              <div className="flex flex-col lg:gap-8 gap-2">
                <h4 className="text-[14px] font-semibold leading-[19px]">
                  {t("footer.headSix")}
                </h4>
                <div className="flex flex-col text-[12px] font-medium leading-9 max-w-[152px]">
                  <a
                    className="underline underline-offset-4"
                    href="mailto:info@tfcapital.me"
                  >
                    info@tfcapital.me
                  </a>
                  <a
                    className="underline underline-offset-4"
                    href="tel:+38220690525"
                  >
                    +382 20 690 525
                  </a>
                  <NavLink to="/contact#map" className="leading-[18px] pt-2.5">
                    Bulevar Džordža Vašingtona br. 83, Podgorica.
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex lg:flex-row flex-col justify-between pb-[32px] gap-8 border-0 lg:border-b-[1px] border-solid border-[#788497] text-[12px] leading-[19px] text-[#788497] md:items-center items-start">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("footer.textOne"),
                }}
                className="max-w-[505px] "
              ></p>
              <div className="flex lg:flex-row flex-col justify-between md:items-center items-start gap-[22px]">
                <p className="font-bold">{t("footer.textTwo")}</p>
                <div className="flex gap-[15px]">
                  <a
                    href="https://www.facebook.com/TFCapitalAD"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={facebook}
                      className="cursor-pointer"
                      alt="Facebook Logo"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/tf.capital.ad?igsh=MWJ1Y2Nzams5NW43dg%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={instagram}
                      className="cursor-pointer"
                      alt="Instagram Logo"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/tfcapital-me/?viewAsMember=true"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={linkedin}
                      className="cursor-pointer"
                      alt="Linkedin Logo"
                    />
                  </a>
                  <a
                    href="https://twitter.com/tfcapitalme"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={x}
                      className=" cursor-pointer"
                      alt="Twitter Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex justify-between lg:flex-row flex-col lg:items-start items-center text-[10px] leading-[14px] font-bold gap-4">
              <p className="lg:border-0 border-b-[1px] border-solid border-black pb-6">
                <NavLink to="/cookiePolicy">{t("footer.cookiePolicy")}</NavLink>
                {" • "}

                <NavLink to="/privacyPolicy">
                  {t("footer.privacyPolicy")}
                </NavLink>
                {" • "}
                <NavLink to="/termsConditions">
                  {t("footer.termsOfUse")}
                </NavLink>
              </p>
              <p className="text-[#788497]">
                🐓 Designed by{" "}
                <a href="https://qqriq.me/" target="_blank" rel="noreferrer">
                  QQRIQ™
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterNew;
