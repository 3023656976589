import qrCode from "../../assets/qr_code_new.svg";
import iosBtn from "../../assets/iosBtn.svg";
import googleBtn from "../../assets/googleBtn.svg";
import { useTranslation } from "react-i18next";

function Scan() {
  const { t } = useTranslation("usaBerza");

  return (
    <div className="scan-store-bg-small w-full lg:py-[80px] py-[72px]">
      <div className="mx-auto container px-[16px] lg:px-[20px]">
        <div className="flex lg:items-center justify-between lg:gap-4 gap-9 lg:flex-row flex-col">
          <div className="flex flex-col xl:gap-9 gap-7 xl:max-w-[488px] max-w-[488px] lg:max-w-[380px]">
            <h3
              dangerouslySetInnerHTML={{
                __html: t("sectionThree.title"),
              }}
              className="text-white text-[24px] leading-[120%] xl:text-[30px] xl:leading-[140%] font-medium"
            ></h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t("sectionThree.text"),
              }}
              className="xl:text-[16px] text-[14px] text-white leading-[160%]"
            ></p>
          </div>
          <div
            style={{ boxShadow: "4px 4px 14px 0px rgba(0, 0, 0, 0.20)" }}
            className="lg:flex hidden xl:w-[180px] xl:h-[180px] w-[140px] h-[140px] rounded-[12px] p-3 items-center justify-center bg-[#163A69] border border-[#C8E64C]"
          >
            <img src={qrCode} alt="qr-code" />
          </div>
          <div className=" flex flex-col gap-8">
            <div className="lg:block hidden text-white font-medium text-[24px] leading-[120%] xl:text-[30px] xl:leading-[140%]">
              <h4>{t("scanTitle.titleOne")}</h4>
              <h4>{t("scanTitle.titleTwo")}</h4>
              <h4>{t("scanTitle.titleThree")}</h4>
            </div>
            <div className="flex items-center gap-3">
              <a
                href="https://apps.apple.com/me/app/tf-trader-investment-ally/id6474622913"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={iosBtn}
                  alt="apple-btn"
                  className="lg:w-[96px] lg:h-[30px] w-[108px] h-[34px]"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.incoditech.tfcapital&pcampaignid=web_share"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={googleBtn}
                  alt="apple-btn"
                  className="lg:w-[96px] lg:h-[30px] w-[108px] h-[34px]"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scan;
