import { useTranslation } from "react-i18next";
import appleBtn from "../../assets/appleBtn.svg";
import googleBtn from "../../assets/googleBtn.svg";
import qrCodeImg from "../../assets/qr_code_new.svg";
import montenegroberzaImg from "../../assets/montenegroberzacard.png";
import qrCodeCover from "../../assets/Bg-Green-Graphic.png";
import metatraderIcon from "../../assets/metatrader_logo.svg";
import { useEffect, useState } from "react";
import Carousel from "./Carousel.js";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, A11y } from "swiper/modules";
import TextTransition, { presets } from "react-text-transition";
import numbersContainerImg from "../../assets/number_container_2.png";
import forexMaskImg from "../../assets/forex-mask.png";
import laptopImg from "../../assets/laptop.png";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function HeroSection() {
  const { t } = useTranslation("home");
  const [imgWidth, setImgWidth] = useState(365);
  const [imgHeight, setImgHeight] = useState(465);
  const navigate = useNavigate();

  const TEXTS = [
    t("heroSection.simple"),
    t("heroSection.safe"),
    t("heroSection.transparent"),
  ];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000);
    return () => clearTimeout(intervalId);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth > 1535) {
        setImgWidth(365);
        setImgHeight(465);
      } else if (windowWidth > 1279 && windowWidth <= 1535) {
        setImgWidth(200);
        setImgHeight(358);
      } else if (windowWidth > 1024 && windowWidth <= 1279) {
        setImgWidth(90);
        setImgHeight(358);
      } else if (windowWidth > 768 && windowWidth <= 1024) {
        setImgWidth(205);
        setImgHeight(460);
      } else if (windowWidth > 640 && windowWidth <= 768) {
        setImgWidth(140);
        setImgHeight(460);
      } else {
        setImgWidth(0);
        setImgHeight(450);
      }
    };
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function FirstSlide() {
    return (
      <>
        <div
          style={{ height: imgHeight + "px" }}
          className="relative gap-[23px] py-[57px] px-[51px] lg:px-[39px] 2xl:py-[57px] 2xl:px-[51px] lg:pt-[43px] lg:pb-[37px] 2xl:w-[330px] w-[330px] lg:w-[254px] flex flex-col first-slide-hero  justify-center"
        >
          <img
            src={qrCodeCover}
            alt="qr-code-cover"
            className="absolute bottom-0 left-0  object-cover object-center z-10 w-full"
          />
          <div className="pb-[11px] 2xl:pb-[11px] flex flex-col gap-3 items-center justify-center text-center">
            <h3 className=" text-white font-bold text-[32px] leading-[38px] 2xl:text-[32px] 2xl:leading-[38px] lg:text-[24px] lg:leading-[30px]">
              {t("firstSlide.title")}
            </h3>
            <p className="text-white text-[12px] leading-[14px]">
              {t("firstSlide.text")}
            </p>
          </div>
          <div className="z-20 relative p-3 rounded-2xl mx-auto mb-[42px] 2xl:mb-[42px] lg:mb-0 2xl:w-[154px] border-[3px] border-[#c8e64c] w-[142px] lg:w-[114px] flex items-center justify-center bg-[#163A69]">
            <img
              src={qrCodeImg}
              alt="qr-code"
              className="relative z-20 w-full h-full"
            />
          </div>
          <p className="text-white text-[12px] font-medium leading-[14px] text-center">
            {t("firstSlide.download")}{" "}
            <span className="text-[#c8e64c] font-bold">
              {t("firstSlide.download2")}
            </span>{" "}
            {t("firstSlide.download3")}
          </p>
          <div className="flex items-center justify-center mx-auto gap-[8px] mt-[-10px]">
            <img
              src={appleBtn}
              alt="appleBtn"
              className="lg:w-[90px] w-[100px] "
            />
            <img
              src={googleBtn}
              alt="googleBtn"
              className="lg:w-[90px] w-[100px] "
            />
          </div>
        </div>
      </>
    );
  }

  function SecondSlide() {
    return (
      <>
        <div
          style={{
            height: imgHeight + "px",
          }}
          className="relative gap-[23px] py-[57px] px-[19px] 2xl:py-[57px] 2xl:px-[25px] lg:pt-[43px] lg:pb-[37px] 2xl:w-[330px] w-[330px] lg:w-[254px] flex flex-col second-slide-hero  justify-between"
        >
          <div className="text-white flex flex-col gap-3 items-center justify-center text-center">
            <h3 className=" font-bold text-[32px] leading-[38px] 2xl:text-[32px] 2xl:leading-[38px] lg:text-[24px] lg:leading-[30px]">
              {t("secondSlide.title2")}
            </h3>
            <p className="text-white text-[12px] leading-[14px]">
              {t("secondSlide.text")}
            </p>
          </div>
          <div
            style={{
              backgroundImage: `url(${numbersContainerImg})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: imgHeight + "px",
            }}
            className="absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%] w-[85%]"
          ></div>
          <div className="flex flex-col items-center justify-center text-center gap-3">
            <img src={montenegroberzaImg} alt="montenegroberza" />
            <p className="text-[#fff] text-[10px] leading-[12px] text-center underline relative z-50">
              {t("secondSlide.button")}
            </p>
          </div>
        </div>
      </>
    );
  }

  function ThirdSlide() {
    return (
      <>
        <div
          style={{
            backgroundImage: `url(${forexMaskImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: imgHeight + "px",
          }}
          className="relative gap-[23px] py-[57px] px-[51px] lg:px-[39px] 2xl:py-[57px] 2xl:px-[51px] lg:pt-[43px] lg:pb-[37px] 2xl:w-[330px] w-[330px] lg:w-[254px] flex flex-col third-slide-hero  justify-between"
        >
          <div className="absolute z-10 top-0 left-0 w-full h-full bg-white bg-opacity-[0.85]"></div>
          <div className="relative z-20  flex flex-col gap-3 items-center justify-center text-center">
            <h3 className="text-[#293B84] font-bold text-[32px] leading-[38px] 2xl:text-[32px] 2xl:leading-[38px] lg:text-[24px] lg:leading-[30px]">
              {t("thirdSlide.title")}
            </h3>
            <p className="text-black text-[12px] font-medium leading-[14px]">
              {t("thirdSlide.text")}
            </p>
          </div>

          <img
            src={laptopImg}
            alt="laptop"
            className="w-full h-full relative z-20"
          />

          <div className="relative z-20 flex flex-col gap-[6px]">
            <div className="flex items-center justify-center mx-auto gap-[8px]">
              <img src={metatraderIcon} alt="appleBtn" className="w-[100px]" />
            </div>
            <div className="">
              <p className="text-[10px] text-[#F28522] text-center mt-0">
                Web • MacOS • Windows
              </p>
              <p className="text-[10px] text-[#F28522] text-center">
                iOS • Android
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="relative overflow-x-hidden z-[99] flex flex-col lg:pb-0 pb-[50px]">
      <div className="w-full flex items-start lg:items-center justify-between gap-[50px] lg:flex-row flex-col lg:pt-[50px] pt-[48px] pb-[50px] hero-main">
        <div className="flex flex-col gap-[16px] lg:w-[452px]">
          <div className="flex flex-col gap-[31px]">
            <p className="text-[#788497] text-[16px] leading-[20px] font-bold">
              {t("heroSection.subtitle")}
            </p>
            <div className="lg:text-[55px] lg:leading-[66px] text-[46px] font-medium leading-[55px] text-black">
              <h1>{t("heroSection.title")}</h1>
              <h1 className="text-[#345BCC] transition-all duration-300 font-bold">
                <TextTransition direction="left" springConfig={presets.wobbly}>
                  {TEXTS[index % TEXTS.length]}
                </TextTransition>
              </h1>
            </div>
          </div>
          <div className="flex flex-col items-start gap-[31px]">
            <div className="lg:text-base text-sm text-black lg:max-w-[350px]">
              <p>{t("heroSection.description")}</p>
              <p className="text-[#788497] lg:pt-0 pt-[8px]">
                {t("heroSection.hashtag")}
              </p>
            </div>
            <NavLink
              to="/#products"
              className="button-big button-primary"
            >
              {t("heroSection.button")}
            </NavLink>
          </div>
        </div>
        <div className="w-full flex-1 hero-slider overflow-visible">
          <div className="bigger">
            <Carousel
              imgList={[<FirstSlide />, <SecondSlide />, <ThirdSlide />]}
              visibleImages={3}
              img_width={imgWidth}
              img_height={imgHeight}
              duration={500}
            />
          </div>
          <div className="smaller">
            <Swiper
              modules={[Pagination, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <NavLink
                  to="/usa-berza"
                  className="flex items-center justify-center"
                >
                  <FirstSlide />
                </NavLink>
              </SwiperSlide>
              <SwiperSlide>
                <NavLink
                  to="/montenegroberze"
                  className="flex items-center justify-center"
                >
                  <SecondSlide />
                </NavLink>
              </SwiperSlide>
              <SwiperSlide>
                <NavLink
                  to="/forex"
                  className="flex items-center justify-center"
                >
                  <ThirdSlide />
                </NavLink>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        {/* <div className="h-[516px] lg:w-[300px] w-full border-2 border-red-500"></div> */}
      </div>
      <div className="lg:w-[62%] w-[80%] mx-auto h-[1px] bg-[#CED5DF]"></div>
      <div>
        <p className="lg:w-[1030px] mx-auto text-center text-[#788497] font-medium lg:pt-[50px] pt-[24px] text-[12px] leading-[19px]">
          {t("heroSection.bigDescription")}
        </p>
      </div>
    </div>
  );
}

export default HeroSection;
