import appleBtn from "../../assets/app-store-new.svg";
import googleBtn from "../../assets/google-play-new.svg";
import qrCodeImg from "../../assets/qr_code_new.svg";
import iphonesImg from "../../assets/iphone-image.png";
import scanBgVectorImg from "../../assets/Vector-7.png";
import metaLogo from "../../assets/meta_logo.svg";
import googleLogo from "../../assets/google_logo.svg";
import appleLogo from "../../assets/apple_logo.svg";
import teslaLogo from "../../assets/tesla_logo.svg";
import microsoftLogo from "../../assets/microsoft_logo.svg";
import amazonLogo from "../../assets/amazon_logo.svg";
import nvidiaLogo from "../../assets/nvidia_logo.svg";
import InfiniteLooper from "../InfiniteLooper/InfiniteLooper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ScanStore() {
  const navigate = useNavigate();
  const { t } = useTranslation("home");

  return (
    <div className="scan-store-bg w-full pt-[59px] pb-[39px] relative">
      <div className="container mx-auto lg:px-[20px] px-[16px] flex flex-col lg:gap-[53px] gap-[48px]">
        <div className="flex lg:flex-row flex-col items-center justify-between xl:gap-[100px] gap-[32px] ">
          <div className="relative z-20 flex flex-col gap-[32px] ">
            <div className=" lg:w-auto flex flex-col gap-[36px] text-left">
              <div className="flex flex-col gap-[32px]">
                <span className="font-bold text-[16px] leading-[19px] text-[#C8E64C]">
                  {t("scanStore.subtitle")}
                </span>
                <div className="lg:text-[30px] text-[32px] font-medium leading-[35px] text-white ">
                  <h4>{t("scanStore.titleOne")}</h4>
                  <h4>{t("scanStore.titleTwo")}</h4>
                  <h4>{t("scanStore.titleThree")}</h4>
                </div>
              </div>
              <div className="flex flex-col text-white text-[12px] leading-[19.5px] max-w-[504px]">
                <p className=" ">{t("scanStore.textOne")}</p>
              </div>
            </div>
            <div className="flex items-center flex-row gap-[20px]">
              <button
                onClick={() => navigate("/usa-berza")}
                className="button-big button-primary"
              >
                {t("startInvesting")}
              </button>
              <button
                onClick={() => navigate("/contact#contact")}
                className="button-secondary button-big"
              >
                {t("scheduleConsulting")}
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center gap-[32px] lg:order-2 order-3">
            <div className="relative z-20 qr-code-background w-[138px] h-[138px] p-3 rounded-[12px] border-[2px] border-solid border-[#C8E64C] lg:block hidden">
              <img src={qrCodeImg} alt="qr-code" />
            </div>
            <div className="relative z-20 flex lg:flex-col flex-row gap-[10px]">
              <a
                href="https://apps.apple.com/me/app/tf-trader-investment-ally/id6474622913"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="lg:w-[96px] lg:h-[30px] h-[40px] w-[131px]"
                  src={appleBtn}
                  alt="appleBtn"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.incoditech.tfcapital&pcampaignid=web_share"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="lg:w-[96px] lg:h-[30px] h-[40px] w-[131px]"
                  src={googleBtn}
                  alt="googleBtn"
                />
              </a>
            </div>
          </div>
          <div className="lg:px-0 px-[16px] relative flex lg:order-3 order-2 lg:pt-0 pt-4">
            <img
              src={iphonesImg}
              alt="iphones"
              className="z-20 relative  xl:w-[385px] xl:min-w-[385px] w-[285px] min-w-[285px]"
            />
          </div>
        </div>
        <div className="relative z-20">
          <InfiniteLooper speed="24" direction="left">
            <a href="https://about.meta.com/" target="_blank" rel="noreferrer">
              <img
                src={metaLogo}
                alt="meta_logo"
                className="mr-[50px] lg:mr-[60px] transform lg:scale-75"
              />
            </a>
            <a href="https://www.google.com/" target="_blank" rel="noreferrer">
              <img
                src={googleLogo}
                alt="google_logo"
                className="mr-[50px] lg:mr-[60px] transform lg:scale-75"
              />
            </a>
            <a href="https://www.apple.com" target="_blank" rel="noreferrer">
              <img
                src={appleLogo}
                alt="apple_logo"
                className="mr-[50px] lg:mr-[60px] transform lg:scale-75"
              />
            </a>
            <a href="https://www.tesla.com/" target="_blank" rel="noreferrer">
              <img
                src={teslaLogo}
                alt="tesla_logo"
                className="mr-[50px] lg:mr-[60px] transform lg:scale-75"
              />
            </a>
            <a href="https://www.amazon.com/" target="_blank" rel="noreferrer">
              <img
                src={microsoftLogo}
                alt="microsoft_logo"
                className="mr-[50px] lg:mr-[60px] transform lg:scale-75"
              />
            </a>
            <a href="https://www.amazon.com/" target="_blank" rel="noreferrer">
              <img
                src={amazonLogo}
                alt="amazon_logo"
                className="mr-[50px] lg:mr-[60px] transform lg:scale-75"
              />
            </a>
            <a href="https://www.nvidia.com/" target="_blank" rel="noreferrer">
              <img
                src={nvidiaLogo}
                alt="nvidia_logo"
                className="mr-[50px] lg:mr-[60px] transform lg:scale-75"
              />
            </a>
          </InfiniteLooper>
        </div>
      </div>
      <img
        className="lg:block hidden z-0 absolute w-full right-0 lg:left-auto lg:translate-x-0 lg:translate-y-0 top-[130px] left-[50%] translate-x-[-50%]"
        src={scanBgVectorImg}
        alt="bg"
      />
    </div>
  );
}

export default ScanStore;
